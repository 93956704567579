import cx from 'classnames';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";

import { Button } from '../button';
import { Button2 } from "../button2";
import { OrderForm2 } from "../forms/order-form2";
import { AffiliateContext } from "../../contexts/AffiliateContext";
import { useHomepageTabsContext } from "../../contexts/HomepageTabsContext";

import styles from '../calculator/calculator.module.css';
import { Container } from '../container';
import { MainForm2 } from '../forms/main-form2';
import styles3 from './calc2.module.css';
import styles2 from '../order-form2/order-form2.module.css';

export const Calculator2 = ({ id, className }) => {
  const { activeTab, dispatchAction } = useHomepageTabsContext()
  const [form, setForm] = useState("calc")
  const { t } = useTranslation()

  const affiliateContext = useContext(AffiliateContext)

  useEffect(() => {
    setForm(activeTab)
  }, [activeTab, dispatchAction])

  return (
    <section className={cx(styles.calculatorSection, className)} id={id && id}>
      <Container className={cx("text-center", styles2.container)}>
        <h3 className={cx("sectionTitle", styles.title, styles3.title)}>
          {t("forms.calculator2Title")}
        </h3>
        <div className={styles2.formWrapContainer}>
          <div className={styles2.formInnerContainer}>
            <div className={styles2.formTabs}>
              <Button2
                name="submit"
                color="primary"
                size="sm"
                className={cx(
                  styles2.formTab,
                  form === "calc" && styles2.active
                )}
                handleClick={() => setForm("calc")}
              >
                {t("home.order.tab1Btn")}
              </Button2>
              <Button
                name="submit"
                type="primary"
                size="sm"
                className={cx(
                  styles2.formTab,
                  form === "order" && styles2.active
                )}
                handleClick={() => setForm("order")}
              >
                {t("home.order.tab2Btn")}
              </Button>
            </div>

            {form === "calc" && <MainForm2 />}
            {form === "order" && <OrderForm2 />}
          </div>
        </div>
      </Container>
    </section>
  )
}
